import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum AIPanelTipType {
    ASK_ME_A_QUESTION = 'ASK_ME_A_QUESTION',
    QUESTIONS_ABOUT_WORKFLOWS = 'QUESTIONS_ABOUT_WORKFLOWS',
    QUESTIONS_ABOUT_COMMUNITY_WORKFLOWS = 'QUESTIONS_ABOUT_COMMUNITY_WORKFLOWS',
    HAVE_A_RESUME = 'HAVE_A_RESUME',
    GUIDE_INTERACTA = 'GUIDE_INTERACTA',
}

export type AIPanelTip = {
    title: string;
    description: string;
    buttonLabel: string;
    imageSrc: string;
};

export const AIPanelTips: { [key in AIPanelTipType]: AIPanelTip } = {
    [AIPanelTipType.ASK_ME_A_QUESTION]: {
        title: 'AI.ASK_ME_A_QUESTION',
        description: 'AI.ASK_ME_A_QUESTION_DESCRIPTION',
        buttonLabel: 'AI.LETS_START_A_CONVERSATION',
        imageSrc: 'assets/shared/images/pro-tip/comments.png',
    },
    [AIPanelTipType.QUESTIONS_ABOUT_WORKFLOWS]: {
        title: 'AI.QUESTIONS_ABOUT_WORKFLOWS',
        description: 'AI.QUESTIONS_ABOUT_WORKFLOWS_DESCRIPTION',
        buttonLabel: 'AI.LETS_TALK_ABOUT_WORKFLOWS',
        imageSrc: './assets/images-2.0/doodles/500x500/workflow.png',
    },
    [AIPanelTipType.QUESTIONS_ABOUT_COMMUNITY_WORKFLOWS]: {
        title: 'AI.QUESTIONS_ABOUT_WORKFLOWS',
        description: 'AI.QUESTIONS_ABOUT_COMMUNITY_WORKFLOWS_DESCRIPTION',
        buttonLabel: 'AI.LETS_TALK_ABOUT_WORKFLOWS',
        imageSrc: './assets/images-2.0/doodles/500x500/workflow.png',
    },
    [AIPanelTipType.HAVE_A_RESUME]: {
        title: 'AI.HAVE_A_RESUME',
        description: 'AI.HAVE_A_RESUME_DESCRIPTION',
        buttonLabel: 'AI.GET_RESUME',
        imageSrc: './assets/shared/images/ai/ruoli_mini.png',
    },
    [AIPanelTipType.GUIDE_INTERACTA]: {
        title: 'AI.GUIDE_INTERACTA',
        description: 'AI.GUIDE_INTERACTA_DESCRIPTION',
        buttonLabel: 'AI.GUIDE_INTERACTA_BUTTON',
        imageSrc: './assets/images-2.0/doodles/idea-management.png',
    },
};

@Component({
    selector: 'interacta-ai-panel-tip',
    templateUrl: `./ai-panel-tip.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIPanelTipComponent implements OnChanges {
    @Input({ required: true }) type!: AIPanelTipType;

    AIPanelTipType = AIPanelTipType;
    tip$ = new BehaviorSubject<AIPanelTip | null>(null);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.type) {
            this.tip$.next(AIPanelTips[this.type]);
        }
    }

    @Output() tipButtonClicked = new EventEmitter();
}
