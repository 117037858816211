<interacta-user-menu-title
    [label]="'USER_MENU.SELECT_THEME' | translate"
    (back)="back.emit()"
/>

<interacta-user-menu-separator />

<div class="flex items-start justify-center space-x-8 py-16">
    <button
        cdkFocusInitial
        interacta-button-menu-vertical
        role="menuitem"
        type="button"
        [icon]="'sun'"
        [label]="'THEME.LIGHT' | translate"
        [selected]="!currentTheme.isSystem && currentTheme.mode === 'light'"
        (click)="changeTheme.emit('light')"
    ></button>
    <button
        interacta-button-menu-vertical
        role="menuitem"
        type="button"
        [icon]="'moon'"
        [label]="'THEME.DARK' | translate"
        [selected]="!currentTheme.isSystem && currentTheme.mode === 'dark'"
        (click)="changeTheme.emit('dark')"
    ></button>
    <button
        interacta-button-menu-vertical
        role="menuitem"
        type="button"
        [icon]="'half-sun'"
        [label]="'THEME.DEFAULT' | translate"
        [selected]="currentTheme.isSystem"
        (click)="changeTheme.emit('system')"
    ></button>
</div>
