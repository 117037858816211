import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { InteractaIcon } from '@interacta-shared/ui-icons';

@Component({
    selector: 'button[interacta-user-menu-button]',
    templateUrl: './user-menu-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuButtonComponent {
    @Input() icon!: InteractaIcon;
    @Input() withArrow = false;
    @Input() width: 'content' | 'full' = 'content';
    @Input() @HostBinding('disabled') disabled = false;
}
