<article
    *ngIf="{
        openFeedbackDetail: openFeedbackDetail$ | async,
        triggerFeedbackSuccessfulMessage:
            triggerFeedbackSuccessfulMessage$ | async,
        canSendFeedback: last || (!last && item.like === undefined),
        attachment: attachmentDetail$ | async,
        messagesCount: messagesCount$ | async,
        hasErrors: hasErrors$ | async,
    } as $"
    class="flex flex-col gap-y-8"
>
    @if (item.messageType !== AIMessageType.ATTACHMENT) {
        <section
            class="flex w-full"
            [ngClass]="{
                'justify-end': item.role === AIRole.USER,
                'justify-start': item.role === AIRole.MODEL,
            }"
        >
            <section
                class="typo-sm shadow-chat flex flex-col gap-y-8 px-12 py-8"
                [ngClass]="{
                    'bg-surface-chat-A max-w-1/2 rounded-l-2xl rounded-tr-2xl':
                        item.role === AIRole.USER,
                    'bg-surface-A w-full rounded-r-2xl rounded-tl-2xl':
                        item.role === AIRole.MODEL,
                }"
            >
                <section class="flex gap-x-8">
                    <interacta-image
                        *ngIf="item.role === AIRole.MODEL"
                        class="shrink-0"
                        [alt]="'AInteracta logo'"
                        [classes]="'w-24 h-24'"
                        [src]="
                            theme === 'dark'
                                ? './assets/shared/images/ai/ai-interacta-logo_dark.svg'
                                : './assets/shared/images/ai/ai-interacta-logo_light.svg'
                        "
                    />

                    <div class="relative grow overflow-hidden">
                        <p
                            class="inline w-full break-words"
                            [innerHtml]="displayedMessage() | safeHtml"
                        ></p>
                        <span
                            class="inline-block w-80"
                            [attr.aria-hidden]="true"
                        ></span>
                        <time
                            class="text-text-mid-contrast absolute bottom-0 right-12"
                            >{{ item.timestamp | interactaDate: 'time' }}</time
                        >
                    </div>
                </section>
                @if (item.messageType !== AIMessageType.EXAMPLE) {
                    <interacta-separator
                        *ngIf="
                            item.role === AIRole.MODEL &&
                            [
                                AIMessageType.STANDARD,
                                AIMessageType.ERROR,
                            ].includes(item.messageType)
                        "
                        [thickness]="'soft'"
                    />
                    <section
                        *ngIf="item.role === AIRole.MODEL"
                        class="flex items-center gap-x-8"
                    >
                        @if (item.messageType === AIMessageType.STANDARD) {
                            <button
                                interacta-icon-button
                                type="button"
                                [active]="item.like === true"
                                [attr.aria-label]="'AI.LIKE' | translate"
                                [disabled]="!$.canSendFeedback"
                                [icon]="'thumb-up'"
                                [matTooltip]="'AI.LIKE' | translate"
                                [size]="'extra-small'"
                                (click)="setLike(item.like, true)"
                            ></button>
                            <button
                                interacta-icon-button
                                type="button"
                                [active]="item.like === false"
                                [attr.aria-label]="'AI.DISLIKE' | translate"
                                [disabled]="!$.canSendFeedback"
                                [icon]="'thumb-down'"
                                [matTooltip]="'AI.DISLIKE' | translate"
                                [size]="'extra-small'"
                                (click)="setLike(item.like, false)"
                            ></button>
                            <button
                                interacta-icon-button
                                type="button"
                                [attr.aria-label]="'AI.COPY' | translate"
                                [icon]="'copy'"
                                [matTooltip]="'AI.COPY' | translate"
                                [size]="'extra-small'"
                                (click)="copyText()"
                            ></button>
                        } @else if (item.messageType === AIMessageType.ERROR) {
                            <button
                                interacta-button
                                type="button"
                                [appearance]="'ghost'"
                                [bgColor]="'textColor'"
                                [icon]="'retry'"
                                [label]="'AI.RETRY' | translate"
                                (click)="retry.emit()"
                            ></button>
                        }
                    </section>
                }
            </section>
        </section>
    } @else {
        <section class="flex w-full flex-col items-end justify-center">
            @if ($.attachment) {
                <interacta-ai-message-attachment-chip
                    class="min-w-352 max-w-1/2"
                    [attachment]="$.attachment"
                    [hasErrors]="$.hasErrors ?? false"
                    [messagesCount]="$.messagesCount ?? 0"
                    [showThreadButton]="threadId === MAIN_THREAD"
                    (attachmentClicked)="
                        openAttachmentPreview.emit($.attachment)
                    "
                    (goToThread)="goToThread.emit($.attachment.id)"
                />
            } @else {
                <div
                    class="w-352 bg-surface-300 h-100 animate-pulse rounded-2xl"
                ></div>
            }
            <interacta-separator
                *ngIf="threadId !== MAIN_THREAD"
                class="mb-16 mt-12 w-full"
                [thickness]="'soft'"
            />
        </section>
    }
    @if ($.triggerFeedbackSuccessfulMessage) {
        <section
            class="bg-surface-A py-18 flex w-full items-center gap-x-16 rounded-2xl px-16"
        >
            <interacta-icon
                class="text-system-success size-24 shrink-0"
                [icon]="'check'"
            />
            <p class="typo-sm flex-grow">
                {{ 'AI.FEEDBACK.THANKS_FOR_YOUR_FEEDBACK' | translate }}
            </p>
            <button
                class="shrink-0"
                interacta-icon-button
                type="button"
                [icon]="'close'"
                [size]="'regular'"
                [style]="'ghost'"
                (click)="feedbackDetailSent$.next(false)"
            ></button>
        </section>
    }
    @if (
        $.openFeedbackDetail &&
        item.like !== undefined &&
        item.role === AIRole.MODEL
    ) {
        <interacta-ai-feedback-detail
            class="w-full"
            [feedback]="item.like ? 'positive' : 'negative'"
            (closeClicked)="openFeedbackDetail$.next(false)"
            (sendFeedbackDetail)="sendFeedbackDetail($event)"
        />
    }
</article>
