import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Theme, ThemeOption } from '@interacta-shared/data-access-theme';

@Component({
    selector: 'interacta-user-menu-theme',
    templateUrl: './user-menu-theme.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuThemeComponent {
    @Input() currentTheme!: Theme;

    @Output() changeTheme = new EventEmitter<ThemeOption>();
    @Output() back = new EventEmitter();
}
