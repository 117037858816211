import {
    ChangeDetectionStrategy,
    Component,
    Input,
    computed,
    inject,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { isDefined } from '@interacta-shared/util';
import { UserCapabilities } from '@modules/core';
import { UserCapabilitiesService } from '@modules/core/services/user-capabilites.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { SidebarSectionAdminId } from '@modules/sidebar/models/sidebar.model';
import {
    filterHiddenSections,
    routeToSidebarSectionAdmin,
    sidebarSectionAdminGroups,
} from '@modules/sidebar/models/sidebar.utils';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { SidebarSectionAdmin } from './../../models/sidebar.model';

@Component({
    selector: 'interacta-sidebar-admin',
    templateUrl: './sidebar-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarAdminComponent {
    @Input() expanded = true;

    private readonly userCapabilitesService = inject(UserCapabilitiesService);
    private readonly store = inject(Store<AppState>);

    sectionSelected = toSignal(
        this.store.select(AppSelectors.selectRouteState).pipe(
            filter(isDefined),
            map((route) => routeToSidebarSectionAdmin(route)),
        ),
    );

    private sidebarSectionAdminGroups = signal(sidebarSectionAdminGroups);
    visibleSectionAdminGroups = computed(() =>
        this.sidebarSectionAdminGroups()
            .map((group) => ({
                ...group,
                sections: filterHiddenSections(
                    group.sections.map((s) =>
                        this.computeHiddenSection(
                            s,
                            this.userCapabilitesService.userCapabilities(),
                        ),
                    ),
                ),
            }))
            .filter((g) => g.sections.length),
    );

    collapseGroup(index: number): void {
        this.sidebarSectionAdminGroups.update((groups) =>
            groups.map((g, idx) =>
                index === idx ? { ...g, collapsed: !g.collapsed } : g,
            ),
        );
    }

    innerSectionSelected(data: {
        sections: SidebarSectionAdmin[];
        sectionSelected: SidebarSectionAdminId | null;
    }): boolean {
        return data.sections.some((s) => s.id === data.sectionSelected);
    }

    private computeHiddenSection(
        section: SidebarSectionAdmin,
        userCapabilities: UserCapabilities | null,
    ): SidebarSectionAdmin {
        switch (section.id) {
            case 'people-admin':
            case 'user':
            case 'group':
            case 'organization':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageUsers,
                };
            case 'roles':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageRoles,
                };
            case 'workspace':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageWorkspaces,
                };
            case 'community':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageCommunities,
                };
            case 'workflow-template':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageWorkflowTemplates,
                };
            case 'catalog':
                return {
                    ...section,
                    hidden: !userCapabilities?.manageCatalogs,
                };
            case 'system-settings-administrators':
            case 'system-settings-providers':
            case 'system-settings-passwords':
            case 'system-settings-domains':
                return {
                    ...section,
                    hidden:
                        !userCapabilities?.superAdmin &&
                        !userCapabilities?.resellerAdmin,
                };
            case 'index':
                return {
                    ...section,
                    hidden: !userCapabilities?.reindexPosts,
                };
            default:
                return section;
        }
    }
}
