import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { digitalWorkplaceHome } from '@modules/app-routing/routes';
import { getLabelServerTranslationV2 } from '@modules/core/helpers/i18n.utils';
import { DiscoveryService } from '@modules/discovery/services/discovery.service';
import { HomeSelectorItem } from '@modules/header/models/home-selector.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'interacta-home-selector',
    templateUrl: './home-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeSelectorComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) currentHomeId!: number;
    @Input({ required: true }) selectableHomes!: HomeSelectorItem[];
    @Output() changeCurrentHome = new EventEmitter<number>();

    isOpen = false;

    private destroy$ = new Subject<void>();

    private discoveryService = inject(DiscoveryService);
    private configurationService = inject(ConfigurationService);

    getHomeName({
        currentHomeId,
        selectableHomes,
    }: {
        currentHomeId: number;
        selectableHomes: HomeSelectorItem[];
    }): HomeSelectorItem['name'] | undefined {
        return selectableHomes.find(({ id }) => id === currentHomeId)?.name;
    }

    toggleOpen(): void {
        this.isOpen = !this.isOpen;
    }

    itemSelected(item: Pick<HomeSelectorItem, 'id'>): void {
        this.changeCurrentHome.emit(item.id);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectableHomes && this.selectableHomes.length > 1) {
            const newHomes = this.selectableHomes.filter(({ isNew }) => isNew);
            if (newHomes.length) {
                const firstNewHome = newHomes[0];
                if (firstNewHome.id !== this.currentHomeId) {
                    this.discoveryService
                        .openTip(
                            { id: 'new-home', instance: firstNewHome.id },
                            {
                                title: 'DISCOVERY.DIGITAL_WORKPLACE.NEW_HOME.TITLE',
                                message:
                                    'DISCOVERY.DIGITAL_WORKPLACE.NEW_HOME.DESCRIPTION',
                                translateParams: {
                                    home: getLabelServerTranslationV2(
                                        firstNewHome.name,
                                        this.configurationService,
                                        true,
                                    ),
                                },
                                image: 'gardening',
                                payload: { boundary: digitalWorkplaceHome },
                                direction: 'vertical',
                                tipActions: [
                                    {
                                        id: 'newHome',
                                        label: 'DISCOVERY.DIGITAL_WORKPLACE.NEW_HOME.GO_TO_HOME',
                                        data: { id: firstNewHome.id },
                                    },
                                ],
                            },
                        )
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((action) => {
                            if (action !== 'close' && action.id === 'newHome') {
                                this.itemSelected(action.data);
                            }
                        });
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
