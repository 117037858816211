<ng-container
    *ngIf="{
        currentHomeId,
        selectableHomes,
    } as homes"
>
    @if (homes.selectableHomes.length > 1) {
        @let selectedHomeName =
            (homes | apply: getHomeName) ?? '' | currentLanguageV2: true;
        <button
            #openMenu="cdkOverlayOrigin"
            cdkOverlayOrigin
            class="typo-t5 bg-surface-A hover:bg-surface-B inline-flex items-center gap-8 rounded px-12 py-8"
            matRipple
            type="button"
            [attr.aria-controls]="'menu'"
            [attr.aria-expanded]="isOpen"
            [attr.aria-label]="'DIGITAL_WORKPLACE.HOME_LIST' | translate"
            [matTooltip]="'DIGITAL_WORKPLACE.HOME_LIST' | translate"
            (click)="toggleOpen()"
        >
            <h1>
                {{ selectedHomeName }}
            </h1>
            <interacta-icon class="size-24" [icon]="'chevron-small-down'" />
        </button>

        <interacta-menu
            [attr.id]="'menu'"
            [classes]="'min-w-300 max-h-[min(80vh,17.625rem)] py-16'"
            [open]="isOpen"
            [origin]="openMenu"
            [positionX]="'after'"
            (closing)="isOpen = false"
        >
            @for (item of selectableHomes; track item.id) {
                @let homeName = item.name | currentLanguageV2: true;
                <button
                    class="justify-between"
                    interactaArrowKeysItem
                    interactaMenuItemDecorator
                    type="button"
                    (click)="itemSelected(item)"
                >
                    <p class="typo-medium">
                        {{ homeName }}
                    </p>
                    @if (item.isNew) {
                        <p
                            class="typo-xs flex h-24 items-center rounded-full border px-8"
                        >
                            {{ 'DIGITAL_WORKPLACE.NEW_HOME_BADGE' | translate }}
                        </p>
                    }
                </button>
            }
        </interacta-menu>
    } @else {
        <h5 class="typo-t5">
            {{ (homes | apply: getHomeName) ?? '' | currentLanguageV2: true }}
        </h5>
    }
</ng-container>
