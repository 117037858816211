import { InteractaIcon } from '@interacta-shared/ui-icons';
import { IWorkspace } from '@modules/communities/models/workspace.model';
import { AppRouterState } from '@modules/core/store/app-router.serializer';

export type SidebarWorkspace = Pick<
    IWorkspace,
    'id' | 'name' | 'visibleInOrganizationTree' | 'communities'
>;

export type SidebarSectionId =
    | 'home'
    | 'people'
    | 'community'
    | 'manage'
    | 'insights'
    | 'admin-v2'
    | 'digital_workplace_admin';

export type SidebarSectionAdminId =
    | 'people-admin'
    | 'user'
    | 'group'
    | 'organization'
    | 'workspace'
    | 'community'
    | 'workflow-template'
    | 'roles'
    | 'catalog'
    | 'index'
    | 'system-settings-administrators'
    | 'system-settings-providers'
    | 'system-settings-passwords'
    | 'system-settings-domains';

export interface BaseSidebarSection {
    label: string;
    path?: string;
    icon?: InteractaIcon;
    iconPosition?: 'left' | 'right';
    hidden?: boolean;
}
export interface SidebarSection extends BaseSidebarSection {
    id: SidebarSectionId;
    authRequired?: boolean;
}

export interface SidebarSectionAdmin extends BaseSidebarSection {
    id: SidebarSectionAdminId;
}

export interface SidebarSectionAdminGroup {
    label: string;
    icon: InteractaIcon;
    collapsed: boolean;
    sections: SidebarSectionAdmin[];
}

export type SectionSelected = {
    sectionId: SidebarSectionId;
    sectionAdminId: SidebarSectionAdminId | null;
    route: AppRouterState;
};

export const CreateButtonSections: SidebarSectionId[] = [
    'home',
    'community',
    'people',
    'manage',
    'insights',
    'digital_workplace_admin',
    'admin-v2',
];

export const CreateButtonDisabledSections: SidebarSectionId[] = [
    'people',
    'insights',
    'digital_workplace_admin',
];

export const CreateButtonDisabledAdminSections: SidebarSectionAdminId[] = [
    'people-admin',
    'index',
    'system-settings-providers',
    'system-settings-passwords',
];
