<interacta-user-menu-title
    [label]="'USER_MENU.SELECT_LANGUAGE' | translate"
    (back)="back.emit()"
/>

<interacta-user-menu-separator />

<div class="flex flex-col items-center">
    @for (language of availableLanguages; track language.code) {
        <button
            class="w-full"
            interacta-user-menu-button
            role="menuitem"
            type="button"
            [attr.cdkFocusInitial]="$first ? '' : null"
            [ngClass]="{
                'text-text-primary': language.code === currentLanguage.code,
            }"
            [width]="'full'"
            (click)="changeLanguage.emit(language)"
        >
            {{ language.description }}
        </button>
    }
</div>
