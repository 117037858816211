import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Observable } from 'rxjs';
import { delay, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
    private readonly environment = inject(ENVIRONMENT);

    private API_PATH = `${this.environment.apiBasePath.common}/`;
    private API_PATH_MOCK = './assets/api/';
    private API_FORMAT_JSON = '.json';
    public wait1 = 100;
    public wait2 = 20;
    // private API_PATH_ALLOWED: string[] = [ "post-data-for-edit", "/post-definition",'/comments-list', 'comment-capabilities', "global-list","/admin/manage/users/"];
    private API_PATH_ALLOWED: string[] = [
        // 'activity-data-for-change',
        // 'current-environment',
        //'community-list/245',
        //'/post-capabilities',
        //'/post-interaction-ranking',
        //'/data-for-edit',
    ];
    // private API_PATH_ERROR_ALLOWED: any[] = [
    //     { pathAPI: 'execute-post-workflow-operation', type: 'VALIDATION' },
    //     // { pathAPI: "manage/create-post", type: 'VALIDATION' },
    //     // { pathAPI: "update-notifications/unread-activity-events", type: 'UNAUTHORIZED' },
    //     // { pathAPI: "/admin/manage/users/", method: "PUT", type: 'VALIDATION' },
    //     // { pathAPI: "import-from-csv", type: 'OUT-OF-SERVICE' }
    // ];
    private API_PATH_ERROR_ALLOWED: any[] = [];
    private API_MOCK_DELAY = 5000;

    constructor(private http: HttpClient) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (!this.environment.production) {
            let url: string = request.url;
            const method: string = request.method;
            let obj: any = null;
            if ((obj = this.mockPathErrorEnable(url, method))) {
                url = this.urlManipulation(url);
                url +=
                    '.' + method + '.ERROR.' + obj.type + this.API_FORMAT_JSON;

                const req = request.clone({ url: url, method: 'GET' });
                return next.handle(req).pipe(
                    delay(this.API_MOCK_DELAY),
                    mergeMap(() => this.http.get(url)),
                    tap((result: any) => {
                        const error: HttpErrorResponse = new HttpErrorResponse({
                            status: result.status,
                            error: result.error,
                        });
                        throw error;
                    }),
                );
            }

            if (this.mockPathEnable(url)) {
                url = this.urlManipulation(url);
                url += '.' + method + this.API_FORMAT_JSON;

                const req = request.clone({ url: url, method: 'GET' });
                return next.handle(req).pipe(delay(this.API_MOCK_DELAY));
            }
        }

        return next.handle(request);
    }

    private urlManipulation(url: string): string {
        url = url.replace(this.API_PATH, this.API_PATH_MOCK);

        // "api/communities/4/post-metadata" => "api/communities/X/post-metadata"
        if (/[^.]\/[0-9]+\/[^.]/.test(url)) {
            url = url.replace(/\/[0-9]+\//gi, '/X/');
            // "api/post/edit-task/X/occtoken" => "api/post/edit-task"
            // url = url.substring(0, url.indexOf("/X"));
        }
        // remove last details like :id and parameters
        if (/[^.]\/[0-9]+/.test(url)) {
            url = url.substring(0, url.lastIndexOf('/'));
        }
        // remove ?id=etc.
        if (/[^.]+[?]+[^.]/.test(url)) {
            url = url.substring(0, url.lastIndexOf('?'));
        }
        return url;
    }

    private mockPathEnable(url: string): boolean {
        return (
            url.startsWith(this.API_PATH) &&
            this.API_PATH_ALLOWED.filter((item) => url.indexOf(item) != -1)
                .length > 0
        );
    }

    private mockPathErrorEnable(url: string, method: string): any {
        if (!url.startsWith(this.API_PATH)) return null;
        const resPath = this.API_PATH_ERROR_ALLOWED.filter((item) => {
            if (url.indexOf(item.pathAPI) != -1) {
                return !item.method || method === item.method;
            }
            return false;
        });
        return resPath.length > 0 ? resPath[0] : null;
    }
}
