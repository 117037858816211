<interacta-profiled-user
    class="ml-auto block w-max"
    [memberClickBehavior]="'none'"
    [rtl]="true"
    [showInfo]="true"
    [user]="currentUser"
/>

<interacta-user-menu-separator />

<button
    cdkFocusInitial
    class="w-full"
    interacta-user-menu-button
    role="menuitem"
    type="button"
    [disabled]="availableLanguages.length === 0"
    [icon]="'translate'"
    [withArrow]="availableLanguages.length > 0"
    (click)="changePage.emit('language')"
>
    <p>
        {{ 'USER_MENU.LANGUAGE' | translate }}
        <span class="text-text-primary">
            {{ currentLanguage.description }}
        </span>
    </p>
</button>

<button
    class="w-full"
    interacta-user-menu-button
    role="menuitem"
    type="button"
    [icon]="
        currentTheme.isSystem
            ? 'half-sun'
            : currentTheme.mode === 'light'
              ? 'sun'
              : 'moon'
    "
    [withArrow]="true"
    (click)="changePage.emit('theme')"
>
    <p>
        {{ 'USER_MENU.THEME' | translate }}
        <span class="text-text-primary">
            {{
                (currentTheme.isSystem
                    ? 'THEME.DEFAULT'
                    : currentTheme.mode === 'light'
                      ? 'THEME.LIGHT'
                      : 'THEME.DARK'
                ) | translate
            }}
        </span>
    </p>
</button>

<interacta-user-menu-separator />

<button
    class="w-full"
    interacta-user-menu-button
    role="menuitem"
    type="button"
    [icon]="'bell'"
    (click)="openNotificationsSettings.emit()"
>
    <p class="typo-sm">{{ 'USER_MENU.NOTIFICATION_SETTINGS' | translate }}</p>
</button>

<button
    class="w-full"
    interacta-user-menu-button
    role="menuitem"
    type="button"
    [icon]="'person'"
    (click)="openProfile.emit()"
>
    <p class="typo-sm">{{ 'USER_MENU.PROFILE' | translate }}</p>
</button>

<button
    class="w-full"
    interacta-user-menu-button
    role="menuitem"
    type="button"
    [icon]="'undeterminate-circle'"
    (click)="logout.emit()"
>
    <p class="typo-sm">{{ 'SHARED.LOGOUT' | translate }}</p>
</button>
