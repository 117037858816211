<div
    class="typo-sm flex w-full items-center rounded-2xl px-4 py-8 text-left"
    matRipple
    [matRippleDisabled]="disabled"
    [ngClass]="{
        'hover:bg-surface-100': !disabled,
        'justify-between': width === 'content',
        'justify-center': width === 'full',
    }"
>
    <div class="flex items-center">
        @if (icon) {
            <interacta-icon
                class="text-text-low-contrast mr-12 inline-block size-24"
                [icon]="icon"
            />
        }

        <ng-content />
    </div>

    @if (withArrow) {
        <interacta-icon class="size-36" [icon]="'chevron-small-right'" />
    }
</div>
