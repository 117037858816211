<div
    *ngIf="{
        uiStates: workspacesUI$ | async,
        sectionSelected: sectionSelected$ | async,
        sidebarWorkspaces: sidebarWorkspaces$ | async,
        advancedCommunitySidebar: (advancedCommunitySidebar$ | async) ?? false,
        showDashboardLink: (showDashboardLink$ | async) ?? false,
    } as $"
    class="flex size-full flex-col overflow-hidden"
    id="sidebar"
    tabindex="0"
>
    @if (
        $.sectionSelected &&
        createButtonSections.includes($.sectionSelected.sectionId)
    ) {
        <button
            *ngIf="
                $.sectionSelected
                    | apply: createNewButtonLabel
                    | translate as label
            "
            class="my-4 ml-12 w-max shrink-0"
            interacta-button
            type="button"
            [attr.aria-label]="expanded ? undefined : label"
            [disabled]="createNewDisabled()"
            [icon]="'plus'"
            [label]="expanded ? label : ''"
            [matTooltip]="label"
            [matTooltipDisabled]="expanded"
            [size]="'regular'"
            (click)="handleCreateClick($.sectionSelected, selectedCommunity())"
        ></button>
    }
    <!-- Sezione NON scrollabile, Home e Community -->
    @for (
        sidebarSection of sidebarNotScrollableSections;
        track sidebarSection.id
    ) {
        <div
            class="duration-250 transition-transform"
            [attr.inert]="
                showAdvancedCommunityResearch() || showAdminV2Sidebar()
                    ? ''
                    : null
            "
            [style.transform]="
                'translateX(' +
                (showAdvancedCommunityResearch() || showAdminV2Sidebar()
                    ? '-100%'
                    : '0') +
                ')'
            "
        >
            <interacta-sidebar-section
                class="my-8"
                [advancedSidebar]="
                    $.advancedCommunitySidebar &&
                    sidebarSection.id === 'community' &&
                    !showAdvancedCommunityResearch()
                "
                [disabled]="
                    sidebarSection.id === 'community' &&
                    !$.advancedCommunitySidebar
                "
                [expanded]="expanded"
                [id]="
                    $.advancedCommunitySidebar &&
                    sidebarSection.id === 'community'
                        ? 'community'
                        : ''
                "
                [section]="sidebarSection"
                [selected]="$.sectionSelected?.sectionId === sidebarSection.id"
                (toggleAdvancedSidebar)="
                    $.advancedCommunitySidebar &&
                        toggleAdvancedSidebar(
                            sidebarSection.id,
                            showAdvancedCommunityResearch()
                        )
                "
            />
        </div>
    }

    <!-- contenitore della sezione scrollabile -->
    <div
        class="w-252 relative h-full grow overflow-y-auto"
        [attr.inert]="
            showAdvancedCommunityResearch() || showAdminV2Sidebar() ? '' : null
        "
    >
        <div
            class="duration-250 flex h-full flex-col transition-transform"
            [style.transform]="
                'translateX(' +
                (showAdvancedCommunityResearch() || showAdminV2Sidebar()
                    ? '-100%'
                    : '0') +
                ')'
            "
        >
            <interacta-invisible-scroll class="min-h-0 grow">
                <interacta-sidebar-workspace-list
                    *ngIf="!$.advancedCommunitySidebar && $.sidebarWorkspaces"
                    [expanded]="expanded"
                    [pinEnabled]="true"
                    [selectedCommunity]="selectedCommunity()"
                    [showDashboardLink]="$.showDashboardLink"
                    [uiStates]="$.uiStates ?? null"
                    [workspaceList]="$.sidebarWorkspaces"
                    (collapsedWs)="onCollapsedWs($event)"
                    (selectedCommunityChanged)="navigateToCommunity($event)"
                />

                <div class="flex flex-col overflow-x-hidden">
                    @for (
                        sidebarSection of otherVisibleSidebarSections();
                        track sidebarSection.id
                    ) {
                        <interacta-sidebar-section
                            [advancedSidebar]="
                                ($.advancedCommunitySidebar &&
                                    sidebarSection.id === 'community') ||
                                sidebarSection.id === 'admin-v2'
                            "
                            [expanded]="expanded"
                            [id]="sidebarSection.id"
                            [section]="sidebarSection"
                            [selected]="
                                $.sectionSelected?.sectionId ===
                                sidebarSection.id
                            "
                            (toggleAdvancedSidebar)="
                                toggleAdvancedSidebar(
                                    sidebarSection.id,
                                    sidebarSection.id === 'community'
                                        ? showAdvancedCommunityResearch()
                                        : showAdminV2Sidebar()
                                )
                            "
                        />
                    }
                    @if (aiEnabled()) {
                        <button
                            *ngIf="'AI.ASK_AINTERACTA' | translate as label"
                            class="bg-surface-B text-text-mid-contrast hover:text-text relative my-16 ml-8 flex max-h-72 items-center gap-x-8 rounded-xl p-12 transition-all"
                            type="button"
                            [attr.aria-label]="expanded ? undefined : label"
                            [matTooltip]="label"
                            [matTooltipDisabled]="expanded"
                            [ngClass]="{
                                'w-48': !expanded,
                                'w-224': expanded,
                            }"
                            (click)="openAIPanel()"
                        >
                            <interacta-icon
                                class="size-24 shrink-0"
                                [icon]="'book-reader'"
                            />

                            <p
                                class="typo-xs line-clamp-3 overflow-hidden text-left transition-all delay-150"
                                [innerHTML]="
                                    'AI.SIDEBAR_GUIDE_CENTER' | translate
                                "
                                [ngClass]="{
                                    'w-0 scale-0 text-nowrap opacity-0':
                                        !expanded,
                                    'w-full scale-100 opacity-100': expanded,
                                }"
                            ></p>

                            <interacta-image
                                class="absolute -right-6 -top-6"
                                [alt]="''"
                                [classes]="'size-20'"
                                [loading]="'eager'"
                                [src]="
                                    currentTheme.mode === 'dark'
                                        ? './assets/shared/images/ai/ai-interacta-logo_dark.svg'
                                        : './assets/shared/images/ai/ai-interacta-logo_light.svg'
                                "
                            />
                        </button>
                    }
                </div>
            </interacta-invisible-scroll>
        </div>
    </div>

    @if (!showAdvancedCommunityResearch() && !showAdminV2Sidebar()) {
        <interacta-sidebar-logo
            [currentTheme]="currentTheme"
            [enableChristmasEasterEgg]="enableChristmasEasterEgg"
        />
    }

    <!-- TITOLO NON SCROLLABILE DEL SUBMENU COMMUNITY -->
    <div
        *ngIf="showAdvancedCommunityResearch() && $.sidebarWorkspaces"
        class="top-116 w-252 duration-250 absolute flex grow flex-col transition-transform"
        [style.transform]="
            'translateX(' +
            (showAdvancedCommunityResearch() ? '0' : '100%') +
            ')'
        "
        (transitionend)="isAnimatingAdvancedTab.set(false)"
        (transitionstart)="isAnimatingAdvancedTab.set(true)"
    >
        <interacta-sidebar-section
            [expanded]="expanded"
            [id]="'advancedCommunityResearch'"
            [section]="communityAdvancedSection"
            [selected]="true"
            (toggleAdvancedSidebar)="
                toggleAdvancedCommunityResearchAndSwitchFocus(
                    showAdvancedCommunityResearch()
                )
            "
        />
    </div>

    <!-- SUBMENU DELLE COMMUNITY -->
    <div
        class="top-168 w-252 duration-250 absolute flex grow flex-col overflow-y-auto transition-transform"
        [attr.inert]="
            !showAdvancedCommunityResearch() && !showAdminV2Sidebar()
                ? ''
                : null
        "
        [ngClass]="{ 'h-[calc(100%-11rem)]': showAdvancedCommunityResearch() }"
        [style.transform]="
            'translateX(' +
            (showAdvancedCommunityResearch() ? '0' : '100%') +
            ')'
        "
        (transitionend)="isAnimatingAdvancedTab.set(false)"
        (transitionstart)="isAnimatingAdvancedTab.set(true)"
    >
        <interacta-invisible-scroll>
            <interacta-sidebar-advanced-community-research
                *ngIf="$.sidebarWorkspaces"
                class="bg-surface-A"
                [expanded]="expanded"
                [hidden]="
                    !showAdvancedCommunityResearch() &&
                    !isAnimatingAdvancedTab()
                "
                [selectedCommunity]="selectedCommunity()"
                [showDashboardLink]="$.showDashboardLink"
                [uiStates]="$.uiStates ?? null"
                [workspaces]="$.sidebarWorkspaces"
                (collapsedWs)="onCollapsedWs($event)"
            />
        </interacta-invisible-scroll>
    </div>

    <!-- TITOLO NON SCROLLABILE DEL SUBMENU Amministrazione -->
    <div
        *ngIf="showAdminV2Sidebar()"
        class="top-116 w-252 duration-250 absolute grow transition-transform"
        [style.transform]="
            'translateX(' + (showAdminV2Sidebar() ? '0' : '100%') + ')'
        "
        (transitionend)="isAnimatingAdvancedTab.set(false)"
        (transitionstart)="isAnimatingAdvancedTab.set(true)"
    >
        <interacta-sidebar-section
            [expanded]="expanded"
            [id]="'adminV2Sidebar'"
            [section]="adminV2Section"
            [selected]="true"
            (toggleAdvancedSidebar)="
                toggleAdvancedAdminSidebarAndSwitchFocus(showAdminV2Sidebar())
            "
        />
    </div>

    <!-- SUBMENU Amministrazione v2 -->
    <div
        class="top-168 w-252 duration-250 absolute grow overflow-y-auto transition-transform"
        [attr.inert]="
            !showAdvancedCommunityResearch() && !showAdminV2Sidebar()
                ? ''
                : null
        "
        [ngClass]="{ 'h-[calc(100%-11rem)]': showAdminV2Sidebar() }"
        [style.transform]="
            'translateX(' + (showAdminV2Sidebar() ? '0' : '100%') + ')'
        "
        (transitionend)="isAnimatingAdminV2Tab.set(false)"
        (transitionstart)="isAnimatingAdminV2Tab.set(true)"
    >
        <interacta-invisible-scroll>
            <interacta-sidebar-admin
                [expanded]="expanded"
                [hidden]="!showAdminV2Sidebar() && !isAnimatingAdminV2Tab()"
        /></interacta-invisible-scroll>
    </div>
</div>

@if (showCommunitySelectionDialog(); as showCommunitySelectionDialog) {
    <interacta-community-selection-dialog
        [isOpen]="showCommunitySelectionDialog?.isOpen ?? false"
        [recentCommunities]="recentCommunities()"
        [workspaces]="(operativityWorkspaces$ | async) ?? []"
        (closeDialog)="showCommunitySelectionDialog?.callback(null)"
        (selectedCommunity)="showCommunitySelectionDialog?.callback($event)"
    />
}
